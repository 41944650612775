import { Box } from '@mui/material';
// import { useTheme } from '@mui/material';

interface HighlightProps {
    color: string;
    variant: string;
    visible: boolean;
}

const Highlight = ({ variant, color, visible }: HighlightProps) => {
    // const theme = useTheme();

    const sizing: { [key: string]: Record<string, any> } = {
        h0: {
            top: { xs: '5px', md: '7px', xl: '10px' },
            right: '-5px',
            bottom: '-2px',
            left: '-5px',
        },
    };

    return (
        <Box
            component="span"
            sx={{
                position: 'absolute',
                backgroundColor: color,
                zIndex: 0,
                opacity: visible ? '1' : '0',
                ...(sizing[variant] || {}),
            }}
        />
    );
};

export default Highlight;
