import { useEffect, useRef, useState } from 'react';

import Highlight from './components/Highlight';
import SelectedWord from './components/SelectedWord';
import Toolbar from './components/Toolbar';

const AnimatedTitle = ({
    title,
    word,
    selectColor,
    highlightColor,
}: {
    title: string;
    word: string;
    selectColor: string;
    highlightColor: string;
}) => {
    const [pose, setPose] = useState(0);
    const mounted = useRef(false);

    const colors = [highlightColor, '#B99E8C', '#648740', '#e6dbcf', '#DD6D22'];

    // t: time
    // ts: text selected - %
    // to: toolbar open
    // hc: hoover color
    // cc: click color
    // hv: highlight visible

    const poses = [
        { t: 1000, ts: 0, to: false, hc: -1, cc: -1, hv: false },
        { t: 50, ts: 10, to: false, hc: -1, cc: -1, hv: false },
        { t: 90, ts: 20, to: false, hc: -1, cc: -1, hv: false },
        { t: 10, ts: 30, to: false, hc: -1, cc: -1, hv: false },
        { t: 30, ts: 40, to: false, hc: -1, cc: -1, hv: false },
        { t: 10, ts: 50, to: false, hc: -1, cc: -1, hv: false },
        { t: 60, ts: 60, to: false, hc: -1, cc: -1, hv: false },
        { t: 60, ts: 70, to: false, hc: -1, cc: -1, hv: false },
        { t: 60, ts: 80, to: false, hc: -1, cc: -1, hv: false },
        { t: 60, ts: 90, to: false, hc: -1, cc: -1, hv: false },
        { t: 300, ts: 100, to: false, hc: -1, cc: -1, hv: false },
        { t: 100, ts: 100, to: true, hc: -1, cc: -1, hv: false },
        { t: 2000, ts: 100, to: true, hc: -1, cc: -1, hv: false },
        { t: 40, ts: 100, to: true, hc: 2, cc: -1, hv: false },
        { t: 20, ts: 100, to: true, hc: 1, cc: -1, hv: false },
        { t: 300, ts: 100, to: true, hc: 0, cc: -1, hv: false },
        { t: 300, ts: 100, to: true, hc: -1, cc: 0, hv: false },
        { t: 100, ts: 100, to: true, hc: -1, cc: -1, hv: false },
        { t: 100, ts: 0, to: false, hc: -1, cc: -1, hv: true },
    ];

    if (title.length < 1) {
        return null;
    }

    if (title.indexOf(word) < 0) {
        return <>{title}</>;
    }

    const foldTitleFrags = title.split(word);

    const playPose = (tick = 0) => {
        if (tick < poses.length && mounted.current) {
            setTimeout(() => {
                if (mounted.current) {
                    setPose(tick);
                    playPose(tick + 1);
                }
            }, poses[tick].t);
        }
    };

    useEffect(() => {
        mounted.current = true;
        playPose();

        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <>
            {foldTitleFrags[0]}
            <br />
            <SelectedWord word={word} selectPercentage={poses[pose].ts} color={selectColor}>
                <Toolbar
                    colors={colors}
                    visible={poses[pose].to}
                    hoverColor={poses[pose].hc}
                    clickColor={poses[pose].cc}
                />
                <Highlight color={highlightColor} visible={poses[pose].hv} variant="h0" />
            </SelectedWord>
            {foldTitleFrags[1]}
        </>
    );
};

export default AnimatedTitle;
