import { ReactElement } from 'react';

const SelectedWord = ({
    word,
    selectPercentage,
    color,
    children,
}: {
    word: string;
    selectPercentage: number;
    color: string;
    children: ReactElement | ReactElement[];
}) => {
    const numLetters = word.length;
    const lettersSelected = numLetters * (selectPercentage / 100);
    const inSelection = word.substring(0, lettersSelected);
    const afterSelection = word.substring(lettersSelected, numLetters);

    return (
        <span style={{ position: 'relative' }}>
            {children}
            <span style={{ position: 'relative', zIndex: 10 }}>
                <span style={{ backgroundColor: color }}>{inSelection}</span>
                {afterSelection}
            </span>
        </span>
    );
};

export default SelectedWord;
