import { Box, Stack, useTheme } from '@mui/material';

import { useEffect, useState } from 'react';

const Toolbar = ({
    visible,
    hoverColor,
    clickColor,
    colors,
}: {
    visible: boolean;
    hoverColor: number;
    clickColor: number;
    colors: Array<string>;
}) => {
    const theme = useTheme();
    const [animation, setAnimation] = useState('none');

    useEffect(() => {
        if (visible) {
            setAnimation('toolsAnimateIn 0.3s cubic-bezier(.68,.06,.21,1.95) both');
        } else if (animation !== 'none') {
            setAnimation('toolsAnimateOut 0.2s');
        }
    }, [visible]);

    return (
        <Stack
            sx={{
                '@keyframes toolsAnimateIn': {
                    from: {
                        opacity: '0',
                        transform: 'translate(-50%, 0) scale(0.5)',
                    },
                    to: {
                        opacity: '1',
                        transform: 'translate(-50%, -60px) scale(1)',
                    },
                },
                '@keyframes toolsAnimateOut': {
                    from: {
                        opacity: '1',
                        transform: 'translate(-50%, -60px) scale(1)',
                    },
                    to: {
                        opacity: '0',
                        transform: 'translate(-50%, -60px) scale(0)',
                    },
                },
                animation,
                pl: 3,
                py: 3,
                position: 'absolute',
                top: '0',
                left: '0',
                flexDirection: 'row',
                borderRadius: theme.radius.allLarge,
                backgroundColor: theme.palette.common.white,
                boxShadow: theme.shadows[3],
                opacity: '0',
                marginLeft: '50%',
                transform: 'translate(-50%, 0)',
                zIndex: '100',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: '50%',
                    bottom: '-7px',
                    width: '15px',
                    marginLeft: '-7px',
                    height: '15px',
                    backgroundColor: theme.palette.common.white,
                    transform: 'rotate(45deg)',
                }}
            />
            {colors.map((backgroundColor, i) => (
                <Box
                    key={backgroundColor}
                    sx={{
                        mr: 3,
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor,
                        transition: 'transform 0.1s ease-out',
                        transform: hoverColor === i ? 'scale(1.05)' : clickColor === i ? 'scale(0.9)' : 'scale(1)',
                    }}
                />
            ))}
        </Stack>
    );
};

export default Toolbar;
